<template>
    <div>
        <div v-if="authUserPermission['stock-calc-slow-by-manual']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">Stock Calculation by Manual Input</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">
                                <label class="form-control-label"><b>Demand Rate</b>
                                    <el-tooltip content="Frekuensi suatu komponen mengalami kegagalan/kerusakan dalam periode 5 tahun SAP. Disarankan demand rate dibawah nilai 2" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Demand Rate" type="number" v-model="demandRate" :disabled="loadingCalculate">

                                <label class="form-control-label mt-3"><b>Harga Sparepart (IDR)</b>
                                     <el-tooltip content="Harga satuan Spare Part dalam rupiah" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Harga Sparepart" type="text" v-model="hargaSparepart" :disabled="loadingCalculate" @keyup="formatPrice($event, 'hargaSparepart')">

                                <!-- <label class="form-control-label mt-3"><b>Jumlah Component</b>
                                     <el-tooltip content="Jumlah Komponen yang terpasang dalam satu equipment" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Jumlah Sparepart" type="number" v-model="jumlahSparepart" :disabled="loadingCalculate"> -->
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Leadtime (Bulan)</b>
                                    <el-tooltip content="Durasi waktu semenjak user (perencanaan pemeliharaan) menginformasikan permintaan Spare Part sampai dengan waktu kedatangan nya (PR-GR)" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Leadtime (Bulan)" type="number" v-model="leadTime" :disabled="loadingCalculate">
                                
                                
                                <!-- <label class="form-control-label mt-3"><b>Jumlah Equipment</b>
                                    <el-tooltip content="Jumlah Equipment yang digunakan " placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Jumlah Equipment" type="number" v-model="jumlahEquipment" :disabled="loadingCalculate"> -->
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Service Level (%)</b>
                                    <el-tooltip placement="top">
                                        <div slot="content">
                                            <p>Kemampuan memberikan pelayanan kepada user pemeliharaan yang diukur berdasarkan rasio antara tingkat ketersediaan (availability) Material Stock dengan frekuensi permintaan <br>
                                               Service level dapat mengikuti pedoman matriks berikut
                                            </p>
                                            <button type="button" class="btn btn-sm btn-primary mt--3" @click="showImage()">Matriks</button>
                                        </div>
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Service Level (%)" type="number" v-model="serviceLevel" :disabled="loadingCalculate">
                            </div>
                        </div>

                        <div class="text-center mt-5">
                            <input type="button" class="btn btn-danger" value="Run Calculation" @click="calculate()" :disabled="loadingCalculate || disableCalculate">
                            <base-button  type="default" @click="resetParameter"  :disabled="loadingCalculate">Reset</base-button>
                        </div>

                        <hr>

                        <div v-show="calculation != null">
                            <div class="row">
                                <div class="col-12">
                                    <!-- <div  v-if="calculation != null">
                                        <h3>Informasi</h3>
                                        <ul>
                                            <li style="font-size:11pt">Leadtime <b>{{ calculation["Leadtime"] }}</b> Bulan</li>
                                            <li style="font-size:11pt">Demand Rate <b>{{ calculation["Failure Rate"] }}</b></li>
                                        </ul>
                                    </div> -->

                                    <div  v-if="calculation != null">
                                        <h3>Hasil</h3>
                                        <div class="alert alert-success" role="alert" style="font-size:12pt">
                                            Jika service level sebesar <b>{{ calculation["Service Level"] }}%</b> maka direkomendasikan stok sebanyak <b>{{ calculation["Nilai_op"]}}</b> sparepart, dengan total biaya <b>{{formatNumber(calculation["Biaya"] )}}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div v-show="calculation != null">
                                        <h3>Detail Service Level</h3>
                                        <div class="table-responsive"  v-if="calculation != null">
                                            <table class="table table-bordered table-striped">
                                                <thead class="bg-secondary">
                                                    <tr>
                                                        <th style="font-size:9pt" class="text-center">Sparepart
                                                            <el-tooltip content="Jumlah Safety Stock Spare Part MRO" placement="top">
                                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                            </el-tooltip> 
                                                        </th>
                                                        <th style="font-size:9pt" class="text-center">Service Level
                                                            <el-tooltip content="Kemampuan memberikan pelayanan kepada user pemeliharaan yang diukur berdasarkan nilai cumulative distribution function (CDF) distribusi Poisson" placement="top">
                                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                            </el-tooltip> 
                                                        </th>
                                                        <th style="font-size:9pt" class="text-center">Total Biaya
                                                            <el-tooltip content="Total biaya yang dikeluarkan dari total Spare Part yang dipesan" placement="top">
                                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                            </el-tooltip> 
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(data, key) in tables" :key="'table' + key">
                                                        <td style="font-size:11pt">{{ data.sparepart }}</td>
                                                        <td style="font-size:11pt">{{ data.service_level}}</td>
                                                        <td style="font-size:11pt">{{ data.total_harga }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h3  v-if="calculation != null" class="mt-3">Grafik Distribusi</h3>
                                    <canvas id="myChart"></canvas>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5" v-show="calculation == null">
                            <div>
                                <empty></empty>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>

          <modal :show.sync="detailImage" size="lg">
                <template slot="header">
                    <h5 class="modal-title">Detail</h5>
                </template>
                <template>
                    <img src="/assets/smartcat/stock-calc-image.jpg" alt="" class="img-fluid">
                </template>
            </modal>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import axios from 'axios'
    import equipmentCode from '@/services/master/equipmentCode.service';
    import Chart from 'chart.js/dist/chart.js'
import Empty from '../../components/Empty.vue';

    export default {
  components: { Empty },        
        data() {
            return {          
                demandRate: '',
                leadTime: '',
                jumlahEquipment: 1,
                jumlahSparepart: 1,
                serviceLevel: 98,
                hargaSparepart: '',
                calculation:null,
                tables:{
                    sparepart:[],
                    service_level:[],
                    total_harga:[]
                },
                canChart:null,
                loadingCalculate:false,
                detailImage:false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            disableCalculate(){
                if(!!!this.serviceLevel || !!!this.jumlahSparepart || !!!this.jumlahEquipment || !!!this.hargaSparepart || !!!this.demandRate){
                    return true;
                }else{
                    return false;
                }
            }
        },
        watch:{
            'calculation': function(value){
                if(value != null){
                    this.tables = []
                    if(this.canChart){
                        this.canChart.destroy()
                    }
                    console.log(this.calculation)
                    // var sparepartValues =Object.values(this.calculation['Tabel Service Level']['Sparepart'])
                    // var cdfValues =Object.values(this.calculation['Tabel Service Level']['% CDF'])
                    // var totalbiayasValues =Object.values(this.calculation['Tabel Service Level']['Total Harga'])

                    // sparepartValues.forEach((element, index) => {
                    //     var d = {
                    //         sparepart:element,
                    //         service_level:cdfValues[index],
                    //         total_harga:this.formatNumber(totalbiayasValues[index])
                    //     }

                    //     this.tables.push(d)
                    // })

                    // this.tables = this.tables.sort((p1, p2) => (p1.service_level < p2.service_level) ? 1 : (p1.service_level > p2.service_level) ? -1 : 0);
                    var cdfValues =Object.keys(this.calculation['Tabel Service Level']['% CDF'])

                    cdfValues.forEach((element) => {
                        var d = {
                            sparepart:this.calculation['Tabel Service Level']['Sparepart'][element],
                            service_level:this.calculation['Tabel Service Level']['% CDF'][element],
                            total_harga:this.formatNumber(this.calculation['Tabel Service Level']['Total Harga'][element])
                        }
                        this.tables.push(d)

                    })
                    const ctx = document.getElementById('myChart');

                    let datapoints1   = Object.keys(this.calculation["Grafik Distribusi"]["CDF"])
                        
                    let structredData1 = [];
                    let structredData2 = [];
                    let labels = [];

                    datapoints1.forEach((element) => {
                        structredData1.push({
                            cdf:this.calculation["Grafik Distribusi"]["CDF"][element],
                            data:{
                                sl:this.calculation["Grafik Distribusi"]["SL"][element],
                            }
                        })

                        structredData2.push({
                            sp:this.calculation["Grafik Distribusi"]["Sparepart"][element],
                            data:{
                                sl:this.calculation["Grafik Distribusi"]["SL"][element],
                            }
                        })
                    })
                    
                    structredData2 = structredData2.sort((a,b) => {
                                            return a + b
                                    })
                    structredData2.forEach((structure) =>{
                        labels.push(structure.sp)
                    })
                             
                    
                    const data = {
                        labels:labels,
                        datasets: [
                            {
                                label: 'Sparepart',
                                data: structredData1,
                                borderColor: 'rgb(51, 51, 255)',
                                tension: 0.4,
                                parsing:{
                                    yAxisKey:'cdf',
                                    xAxisKey:'data.sl',
                                }
                            },
                            {
                                label: 'Service Level',
                                data: structredData2,
                                borderColor: 'rgb(255, 99, 132)',
                                tension: 0.4,
                                pointRadius:0.6,
                                parsing:{
                                    xAxisKey:'sp',
                                    yAxisKey:'data.sl',

                                },
                                segment:{
                                    borderDash:[6,6]
                                }
                            }, 
                        ]
                    };

                    this.canChart = new Chart(ctx, {
                        type: 'line',
                        data: data,
                        options: {
                            responsive: true,
                            plugins: {
                                tooltip: {
                                    enabled: true,
                                    callbacks: {
                                        label: function(context) {
                                            if(context.dataset.label == 'Sparepart'){
                                                return context.dataset.label + ' ' + context.label;
                                            }else{
                                                return context.dataset.label + ' ' + context.parsed.y;
                                            }
                                        },
                                        title: function(context){
                                            return ''
                                        }
                                    }

                                }
                            },
                            scales: {
                                x: {
                                    suggestedMin: 0,
                                },
                                y: {
                                    suggestedMin: 0,
                                }
                            }
                        },
                    });
                }else{
                    this.tables.sparepart = []
                    this.tables.service_level = []
                    this.tables.total_harga = []
                }
            },
        },
        methods: {
            async calculate() {
                this.loadingCalculate = true;
                this.calculation = null;
                var hargaSparepart = Number(this.hargaSparepart.replaceAll('.','').replaceAll(',','.'))
                let params = JSON.stringify(
                {
                    "demand_rate":Number(this.demandRate),
                    "leadtime":Number(this.leadTime),
                    "service_level":Number(this.serviceLevel),
                    "sparepart_input":Number(this.jumlahSparepart),
                    "equipment_input":Number(this.jumlahEquipment),
                    "harga_sparepart":hargaSparepart,
                    
                })

                var config = {
                    method: 'post',
                    url: 'https://api-sm.pupuk-indonesia.com/stock-slowmoving-manual',
                    // mode: 'no-cors',
                    headers: { 
                        // 'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    },
                    data : params
                };

                await axios(config).then(response =>  this.calculation =  response.data)
                .catch(error => this.calculateError());
                this.loadingCalculate = false;
            },
            formatNumber(value) {
                let convert = value.toString();
                let splitValue  = convert.split('.');
                let withDot = splitValue[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                if(!!splitValue[1]){
                    return 'Rp. ' + withDot + ',' + splitValue[1]
                }else{
                    return 'Rp. ' + withDot
                }
            },
            resetParameter(){
                this.serviceLevel = 98
                this.jumlahSparepart = 1
                this.jumlahEquipment = 1
                this.hargaSparepart = ''
                this.demandRate = ''
                this.leadTime = ''
                this.calculation = null
            },
            formatPrice(val, variabel) {
                var price = val.target.value.replace(/[^,\d]/g, "").toString()
                price = price.replace(/[^,\d]/g, "").toString();
                var split = price.split(",");
                var lastPrice = split[0].length % 3;
                var formatRupiah = split[0].substr(0, lastPrice);
                var ribuan = split[0].substr(lastPrice).match(/\d{3}/gi);

                if (ribuan) {
                    var separator = lastPrice ? "." : "";
                    formatRupiah += separator + ribuan.join(".");
                }
                formatRupiah = split[1] != undefined ? formatRupiah + "," + split[1] : formatRupiah;
                this[variabel] = formatRupiah
            },
            calculateError(){
                this.$notify({
					message: 'Calculate Data is Error',                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            showImage(){
                this.detailImage = !this.detailImage
            }
            
        }   
    };
</script>
<style>
.el-tooltip__popper {
  max-width: 320px !important;
  word-break: break-word;
}
</style>
